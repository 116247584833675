import AboutMe from './components/AboutMe/AboutMe';
import ContactDetails from "./components/ContactDetails/ContactDetails";
import './App.css';
import ProfileImage from './components/ProfileImage/ProfileImage';
import NameHeader from './components/NameHeader/NameHeader';
import ImageCard from './components/ImageCard/ImageCard';

function App() {
    document.title = 'Noel Whitaker';
  return (
    <div className="App">
      <a href="/" className="homeLink">
        noelwhitaker.com
      </a>
      <header className="App-header">
        <ProfileImage/>
        <NameHeader/>
        <ContactDetails/>
      </header>
      <AboutMe />
        <div className="imageCards">
            <ImageCard imageSource="puppymisha1" />
            <ImageCard imageSource="misha2" />
            <ImageCard imageSource="goldentrio" />
        </div>
        <div className="imageCards">
            <ImageCard imageSource="couchmisha" />
            <ImageCard imageSource="goldengang" />
            <ImageCard imageSource="misha" />
        </div>
    </div>
  );
}

export default App;
